import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import '../css/Navbar.css';

export default function Navbar() {
    console.log(window.scrollY);
    const [onTop, setOnTop] = useState(true);
    const [isOpen, setIsOpen] = useState(false);
    const navigate = useNavigate();

    const handleNavigate = (path) => {
        window.scrollTo(0, 0);
        setIsOpen(false);
        navigate(path);
    };

    const handleScroll = () => {
        setOnTop(window.scrollY === 0 ? true : false);
    };

    useEffect(() => {
        // Set the initial scroll position
        setOnTop(window.scrollY === 0 ? true : false);
        // Add the scroll event listener
        window.addEventListener('scroll', handleScroll);
        // Cleanup function to remove the event listener
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <nav className={'navbar'} style={onTop ? { backgroundColor: 'transparent' } : { backgroundColor: '#101010ea' }}>
            <div className="logo-container">
                <img className="logo" src="white-logo.png" alt="Amiin" onClick={() => handleNavigate('/')} />
            </div>
            <div className="desktop-links">
                <ul className='links-list'>
                    <li><Link className='link' to="/" onClick={() => handleNavigate('/')}>Home</Link></li>
                    <li><Link className='link' to="/about" onClick={() => handleNavigate('/about')}>About</Link></li>
                    <li><Link className='link' to="/projects" onClick={() => handleNavigate('/projects')}>Projects</Link></li>
                    <li><Link className='link' to="/contact" onClick={() => handleNavigate('/contact')}>Contact</Link></li>
                </ul>
                <ul className="social-links">
                    <img onClick={() => window.open('https://www.facebook.com/A.Amiiiiin', '_blank')} className='social-link' src="logos/facebook.svg" alt="" />
                    <img onClick={() => window.open('https://www.instagram.com/a.amiiiiiin/', '_blank')} className='social-link' src="logos/instagram.svg" alt="" />
                </ul>
            </div>
            {isOpen ? (
                <div className="mobile-links" >
                    <ul className='links-list'>
                        <li className='link'  onClick={() => handleNavigate('/')}>Home</li>
                        <li className='link' onClick={() => handleNavigate('/about')}>About</li>
                        <li className='link' onClick={() => handleNavigate('/projects')}>Projects</li>
                        <li className='link' onClick={() => handleNavigate('/contact')}>Contact</li>
                    </ul>
                    <ul className="social-links">
                        <img  onClick={() => window.open('https://www.facebook.com/A.Amiiiiin', '_blank')}  className='social-link' src="logos/facebook.svg" alt="" />
                        <img onClick={() => window.open('https://www.instagram.com/a.amiiiiiin/', '_blank')}  className='social-link' src="logos/instagram.svg" alt="" />
                    </ul>
                </div>
            ) : null}
            <div className="hamburger" onClick={() => setIsOpen(prev => !prev)}>
                <div className={`line`}></div>
                <div className={`line`}></div>
                <div className={`line`}></div>
            </div>
        </nav>
    );
}
