import React from 'react'
import Buttons from './Buttons'

import '../css/FlexContainer.css'

export default function HomePageFlex(props) {
  return (
    <div className="flex-container" style={props.reverse ? {flexDirection: 'row-reverse'} : null}>
        <div className="flex-info-chunk">
            <h1 className="small-heading">{props.title}</h1>
            {props.text ? 
            <p className="text">
            {props.text}
            </p> 
            : null}
            <ul className='bullet-list'>
                {props.bulletPoints ? 
                props.bulletPoints.map(point => (
                    <div className="bullet-point">
                        <div className="circle"></div>
                        <li className='bullet text'>{point}</li>
                    </div>
                ))
                : null}
            </ul>
            {props.buttonTypes ? 
            <Buttons main={props.main} link={props.link} mail={props.mail} whatsapp={props.whatsapp}/>
            : <Buttons main link/>}
        </div>
        <div className="flex-image-chunk"  style={props.reverse ? {justifyContent: 'start'} : null}>
            {
                props.image ? 
                <img className='flex-image' src={props.image} alt="Main Image ALSADFJASWOI" />
                :   <video controls className={`flex-image ${props.height?'extra-height' : ''}`} autoPlay muted loop src={props.video}>
                        Your browser does not support the video tag.
                    </video>
            }
        </div>
    </div>
  )
}
