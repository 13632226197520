import React from 'react';
import HomePageFlex from '../components/HomePageFlex';
import '../css/Contact.css';

const Contact = () => {

  return (
    <div className="contact-page">
      <HomePageFlex
        reverse
        title={`Contact me :`}
        text={
           <div className='icons'>
        <span className='icon-container' style={{
            textAlign: 'center',
            fontSize: '24px'
        }}>
            <img 
                src="/logos/phone.svg" 
                style={{width: '30px'}}
                alt="WhatsApp" 
            />
                01150888110
        </span>
    </div> 
          }
        // bulletPoints={['Phone Number: 01150888110']}
        // bulletPoints={['Phone Number: 01150888110', 'Email: abdelrahmanaminxx@gmail.com', 'Instagram: @a.amiiiiiin']}
        image='/contact.jpg'
        buttonTypes
        mail
        whatsapp={true}
      />
    </div>
  );
};

export default Contact;