import React from 'react'
import "../css/SocialProof.css"

export default function SocialProof() {
    const companies = [
      {href: 'logos/laithy.png', },
        {href: 'logos/nas.png', }, 
        {href: 'logos/ghaly.svg', },
        {href: 'logos/haytham.png'},
        {href: 'logos/1.png', },
        {href: 'logos/2.png', },
        {href: 'logos/3.png', },
        {href: 'logos/4.webp', },
        {href: 'logos/weavers.png', },
        {href: 'logos/tui.svg', }
    ]
    return (
      <div className="move-inside">
        <div className="social-proof-container">
          {companies.map((company, index) => (
            <img
              key={index}
              src={company.href}
              alt={`Logo of ${company.href}`}
              className="company"
            />
          ))}
        </div>
      </div>
    )
}
