import React, { useState } from 'react';
import ProjectCard from '../components/ProjectCard';
import '../css/Projects.css';
import Buttons from '../components/Buttons';

import { useParams } from 'react-router-dom';

const artProjects = [
  { link: "/about", href: 'art/1.jpg' },
  { link: "/about", href: 'art/19.jpg' },
  { link: "/about", href: 'art/2.jpg', 
    // span: 2
   },
  { link: "/about", href: 'art/4.jpg' },
  { link: "/about", href: 'art/20.jpg' },
  { link: "/about", href: 'art/21.jpg' },
  { link: "/about", href: 'art/7.jpg', 
    // span: 2
   },
   { link: "/about", href: 'art/5.jpg' },
   { link: "/about", href: 'art/18.jpg' },
   { link: "/about", href: 'art/6.jpg' },
   { link: "/about", href: 'art/17.jpg' },
  { link: "/about", href: 'art/8.jpg'}, 
  { link: "/about", href: 'art/16.jpg', 
    // span: 2
   },
   { link: "/about", href: 'art/3.jpg' },
   { link: "/about", href: 'art/14.jpg' },
   { link: "/about", href: 'art/15.jpg' },
  { link: "/about", href: 'art/9.jpg', 
    // span: 2
   },
  { link: "/about", href: 'art/10.jpg' },
  { link: "/about", href: 'art/13.jpg' },
  { link: "/about", href: 'art/11.jpg' },
  { link: "/about", href: 'art/12.jpg', 
    // span: 2
   },
];

const photographyProjects = [
  { link: "/about", href: 'photos/2.jpg' },
  { link: "/about", href: 'photos/3.jpg' },
  { link: "/about", href: 'photos/7.jpg' },
  { link: "/about", href: 'photos/8.jpg' },
  { link: "/about", href: 'photos/5.jpg' },
];

const videographyProjects = [
  { link: "/about", href: 'vids/1.mp4' , video: true},
  { link: "/about", href: 'vids/2.mp4' , video: true},
  { link: "/about", href: 'vids/3.mp4' , video: true},
  // { link: "/about", href: 'vids/4.mp4' },
];
const graphicDesignProjects = [
  { link: "/about", href: 'designs/1.jpg' },
  { link: "/about", href: 'designs/3.jpg' },
  { link: "/about", href: 'designs/14.mp4', video: true },
  { link: "/about", href: 'designs/4.jpg' },
  { link: "/about", href: 'designs/16.mp4', video: true },
  { link: "/about", href: 'designs/6.jpg' },
  // { link: "/about", href: 'designs/10.jpg' },
  { link: "/about", href: 'designs/11.jpg' },
  { link: "/about", href: 'designs/15.mp4', video: true },
  // { link: "/about", href: 'designs/12.jpg' },
  // { link: "/about", href: 'designs/13.jpg' },
];

const Projects = () => {
  const params = useParams()
  const initial = params.filter ? params.filter : 'videography'

  const [selectedSection, setSelectedSection] = useState(initial);

  const sections = {
    'videography': videographyProjects,
    'graphic-design': graphicDesignProjects,
    'art': artProjects,
    'photography': photographyProjects,
  };

  const handleSectionChange = (section) => {
    setSelectedSection(section);
  };

  const upperCaseFirstLetters = (word) => {
    let final = word.split('-').map(a => `${a[0].toUpperCase()}${a.slice(1, a.length)}`).join(' ')

    return final
  }

  return (
    <div className='projects-page'>
      <div className="project-page-header">
            {/* <h2 className="small-heading">Filter my projects</h2> */}
            <div className="lineup-buttons">
              {Object.keys(sections).map((section) => (
                <Buttons selected={section === selectedSection} lineup text={upperCaseFirstLetters(section)} key={section} onClick={() => handleSectionChange(section)}/>
              ))}
            </div>
      </div>
      <div className="project-section">
        <div 
        // style={{ gridTemplateColumns: `repeat(${sections[selectedSection].length < 5 ? sections[selectedSection].length : 4}, 1fr)` }}
         className="project-section-images">
          {sections[selectedSection].map((project, i) => (
            <ProjectCard 
            type={project.video ? 'video' : null}
              key={i} 
              delay={0.15} 
              image={project.href} 
              span={project.span} 
              text='Open' 
              subtext='Click to open' 
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Projects;