import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import FollowCursor from './components/FollowCursor';
import Footer from './components/Footer';

ReactDOM.render(
    <BrowserRouter>

        <FollowCursor/>
        <App />
        
        <Footer/>
    </BrowserRouter>,
    document.getElementById('root')
);
