import React from 'react'
import HomePageFlex from '../components/HomePageFlex'

import '../css/About.css'

export default function About() {
  return (
    <div className="about-page">
      <div className="about-section">
        <h2 className="secondary-heading">About Me</h2>
        <HomePageFlex
        title="About Me"
        text="I have considered myself an artist for a long time ,
 I graduated from the Faculty of Fine Arts. I delved into the realm of graphic design with some freelancing gigs. 
At first, it was only taking photos, but then I realized photos are not enough to cascade a feeling. Then I went into video making."
        image='photos/6.jpg'
        />
      </div>
      <div className="experience-section">
        <h2 className="secondary-heading">My Experience</h2>
        <HomePageFlex
          title="My professional work experience"
          text="My experience is versatile; I worked at Oriental Weavers for almost 2 years as a graphic designer. I also had many freelancing jobs, including with Abu Ghaly and Nas Production, where we made projects for big companies like El Sewedy & Vodafone, and other governmental projects as well."
          image='photos/1.jpg'
          bulletPoints={[
            'Oriental Weavers: Senior Designer',
            'El Laithy Auto Group: Video Director',
            'TUI Magic Life: Decor Designer',
            'Art And Music House: Art Teacher',
            'Abu Ghaly: Painting Designer',
            'NAS Productions: Video Editor',
            ]}
          reverse/>
      </div>
    </div>
  )
}
