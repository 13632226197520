import React from 'react';
import ProjectSectionCard from './ProjectSectionCard';
import '../css/ProjectSection.css';

export default function ProjectSection({ projects, delay }) {

  return (
    <div className="cool-projects-section">
      <h2 className="small-heading">My Work.</h2>
      <div className="project-showcase-container" >
        {projects.map((project, index) => (
          <ProjectSectionCard
            key={index}
            text={project.text}
            subtext={project.subtext}
            image={project.image}
            delay={0}
            link={project.link}
          />
        ))}
      </div>
    </div>
  );
}
