import React, { useEffect, useRef, useState } from 'react';
import { motion, useInView, useAnimation } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import '../css/ProjectSection.css';

export default function ProjectSectionCard({ text, subtext, image, delay, link }) {
  const getDeviceType = () => {
    const userAgent = navigator.userAgent;

    if (/mobile/i.test(userAgent)) {
      return 'Mobile';
    } else if (/tablet/i.test(userAgent)) {
      return 'Tablet';
    } else {
      return 'Desktop';
    }
  };

  const isDesktop = getDeviceType() === 'Desktop';
  const [focus, setFocus] = useState(!isDesktop); // Focus is true for mobile/tablet

  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });
  const mainControls = useAnimation();
  const navigate = useNavigate();

  useEffect(() => {
    if (isInView) {
      mainControls.start('visible');
    }
  }, [isInView]);

  const handleNavigate = (path) => {
    window.scrollTo(0, 0);
    navigate(path);
  };

  return (
    <motion.div
      className="project-showcase"
      ref={ref}
      variants={{
        hidden: { y: 75, opacity: 0 },
        visible: { y: 0, opacity: 1 },
      }}
      initial="hidden"
      animate={mainControls}
      transition={{ duration: 0.5, delay: 0.25 + delay }}
    >
      <div
        className="project-showcase-info"
        style={{ opacity: focus ? 1 : 0 }}
      >
        <div className="text-container">
          <p className="big-text">{text}</p>
          <p className="text">{subtext}</p>
        </div>
        <img className="right-arrow" src="right-arrow.svg" alt="" />
      </div>
      <motion.img
        onClick={() => handleNavigate(link)}
        className="project-showcase-image"
        src={image}
        alt=""
        style={{
          filter: !isDesktop ? 'brightness(0.3) blur(2px)' : 'none',
        }}
        whileHover={!isDesktop ? {} : {
          filter: 'brightness(0.3) blur(2px)',
          transition: { duration: 0.3, ease: ['easeOut'] },
        }}
        onHoverStart={() => isDesktop && setFocus(true)}
        onHoverEnd={() => isDesktop && setFocus(false)}
      />
    </motion.div>
  );
}