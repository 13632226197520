import React, { useEffect, useState } from 'react';
import Buttons from '../components/Buttons';
import SocialProof from '../components/SocialProof';
import { motion } from 'framer-motion';

import '../css/Home.css';
import HomePageFlex from '../components/HomePageFlex';
import Card from '../components/Card';
import ProjectSection from '../components/ProjectSection';

const Home = () => {
    const projects = [
        { text: 'Photography', subtext: 'Captivating Photos', image: '/photography.png', link: '/projects/photography' },
        { text: 'Videography', subtext: 'Filming and Editing', image: '/videography.png', link: '/projects/videography' },
        { text: 'Graphic Design', subtext: 'Making Designs', image: '/graphic.png', link: '/projects/graphic-design' },
        { text: 'Art', subtext: 'Drawing and Painting', image: '/art.png', link: '/projects/art' },
    ];

    const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });

    useEffect(() => {
        const handleMouseMove = (event) => {
            const { clientX, clientY } = event;
            setMousePosition({ x: clientX, y: clientY });
        };

        window.addEventListener('mousemove', handleMouseMove);
        return () => {
            window.removeEventListener('mousemove', handleMouseMove);
        };
    }, []);

    // Calculate the movement offset
    const movementFactor = 100; // adjust for sensitivity
    const foreImagePosition = {
        x: -(mousePosition.x - window.innerWidth / 2) / movementFactor,
        y: -(mousePosition.y - window.innerHeight / 2) / movementFactor,
    };

    const springConfig = { damping: 20, stiffness: 100 };

    return (
        <>

        <div className='home-page'>  
                          
            <div className="hero-section">
                <div className="info-chunk">
                    <div className="backpop"></div>
                    <h2 className="big-text">Hi, I'm AbdelRahman Amiin</h2>
                    <h1 className="main-heading">An Artist And Videographer In Egypt.</h1>
                    <p className="big-text">
                         Finding Appeal in Everything <br/> Turning Usual Moments into Meaningful Visual Stories.
                    </p>
                    <Buttons main secondary />
                    <SocialProof />
                    </div>

                <div className="image-chunk hide">
                    <img 
                    // style={{
                        
                    //     maskImage: `linear-gradient(to bottom, rgba(255, 255, 255, 1) ${(mousePosition.y - window.innerWidth / 2)/50}%, rgba(255, 255, 255, 0) 100%)`,
                    //     webkitMaskImage: `linear-gradient(to bottom, rgba(255, 255, 255, 1) ${(mousePosition.y - window.innerWidth / 2)/50}%, rgba(255, 255, 255, 0) 100%)`
                    // }}
                    className='main-image backk' src="changed.png" alt="Main Image" />
                    {/* <motion.img
                        className='main-image fore'
                        src="fore.png"
                        alt="Main Image"
                        initial={{ x: 0, y: 0 }} // Initial position
                        animate={{
                            x: foreImagePosition.x,
                            y: foreImagePosition.y,
                        }}
                        transition={{ type: 'spring', ...springConfig }}
                        style={{
                            willChange: 'transform',
                        }}
                    /> */}
                </div>
            </div>
            {/* <div className="social-proof">
                <h2 className="small-heading">Companies I Worked At:</h2>
                <SocialProof />
            </div> */}
            <HomePageFlex
                title="Bringing your brand to life"
                text="All brands have a story, but not all stories are recorded. My aim and passion is to capture and convey the captivating story of your bsuiness with my camera, editing and storytelling."
                // image='/photos/2.jpg'
                video='/vids/1.1.mp4'
                height
            />
            <HomePageFlex
                reverse
                title="Transfering ideas through a camera"
                text={`Through the power of filming and editing, you are able to convey any message to your audience.`}
                
                video='/vids/2.mp4'
                bulletPoints={[
                    'Audience alanysis',
                    'Videos that fit your brand',
                ]}
            />
            <ProjectSection delay={0.1} projects={projects} />
            <div className="explain-section">
                <h2 className="secondary-heading">From Videography <br /> To Production.</h2>
                <p className="big-text" style={{ padding: '0 0%' }}>
                    Click the button to send me an email!
                </p>
                {/* <div className="cards">
                    <Card />
                    <Card focus />
                    <Card />
                </div> */}
                <Buttons main link />
            </div>
        </div></>
    );
};

export default Home;