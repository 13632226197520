import React, {useEffect, useState} from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import Navbar from './components/Navbar'

import Home from './pages/Home';
import About from './pages/About';
import Projects from './pages/Projects';
import Contact from './pages/Contact';

import Footer from './components/Footer';

import './css/Typography.css'
import './css/App.css'
import './css/index.css'

const App = () => {
    const location = useLocation()
    const [atHome, setAtHome] = useState(false)

    useEffect(() => {
        setAtHome(location.pathname == '/')
    }, [location])

    return (
        <>
        {
            atHome ? 
            <div className="main-video-container">
                <video className='main-video' playsInline playsinline muted loop autoPlay src='/vids/11.mp4'>
                    Your browser does not support the video tag.
                </video>
            </div>
            : null
        }
        <div className='main-container'>
          <Navbar/>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/about" element={<About />} />
                <Route path="/projects/:filter?" element={<Projects />} />
                {/* <Route path="/projects" element={<Projects />} /> */}
                <Route path="/contact" element={<Contact />} />
            </Routes>
            
        </div>
        </>
    );
};

export default App;
