import React, { useState, useRef, useEffect } from 'react';
import { motion, useInView, useAnimation } from 'framer-motion';
import Modal from './Modal';
import '../css/ProjectSection.css';

const ProjectCard = (props) => {
  const [vis, setVis] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });
  const mainControls = useAnimation();

  useEffect(() => {
    if (isInView) {
      mainControls.start('visible');
    }
  }, [isInView]);

  const handleImageClick = () => {
    setIsModalOpen(true);
  };

  return (
    <>
      <motion.div
        style={props.span ? { gridColumn: `span ${props.span}` } : null}
        ref={ref}
        className="project-card"
        variants={{
          hidden: { y: 75, opacity: 0 },
          visible: { y: 0, opacity: 1 },
        }}
        initial="hidden"
        animate={mainControls}
        transition={{ duration: 0.5, delay: props.delay }}
      >
        <div className="project-section-image-container">
        {props.type === 'video' ? 
          <motion.video
            className="project-section-image"
            src={`/${props.image}`}
            alt="Project"
            controls
            loop
            autoPlay
            muted
          />
        : 
          <motion.img
            className="project-section-image"
            whileHover={{
              scale: 1.08,
              filter: 'brightness(0.5) blur(5px)',
              transition: { duration: 0.4, ease: ['easeOut'] },
            }}
            onHoverStart={() => setVis((prev) => !prev)}
            onHoverEnd={() => setVis((prev) => !prev)}
            src={`/${props.image}`}
            alt="Project"
            onClick={handleImageClick}
          />
        }
          {vis && (
            <div className="project-showcase-info" style={{ opacity: vis ? 1 : 0 }}>
              <div className="text-container">
                <p className="big-text">{props.text}</p>
                <p className="text">{props.subtext}</p>
              </div>
              <img className="right-arrow" src="right-arrow.svg" alt="" />
            </div>
          )}
        </div>
      </motion.div>
      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} imageSrc={`/${props.image}`} />
    </>
  );
};

export default ProjectCard;
