import React, { useState, useEffect } from 'react';
import { motion, useMotionValue, useSpring } from 'framer-motion';

import '../css/followCursor.css'

const FollowCursor = () => {
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const [isHoveringClickable, setIsHoveringClickable] = useState(false);
  const mouseX = useMotionValue(0);
  const mouseY = useMotionValue(0);

  const springConfig = { damping: 20, stiffness: 300 };
  const x = useSpring(mouseX, springConfig);
  const y = useSpring(mouseY, springConfig);

  useEffect(() => {
    const handleMouseMove = (event) => {
      setMousePosition({ x: event.clientX, y: event.clientY });
      const element = document.elementFromPoint(event.clientX, event.clientY);
      
      // Check if element is not null before accessing its style
      if (element) {
        const isPointer = window.getComputedStyle(element).cursor === 'pointer';
        setIsHoveringClickable(isPointer);
      } else {
        setIsHoveringClickable(false); // Reset if no element is found
      }
    };

    window.addEventListener('mousemove', handleMouseMove);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  useEffect(() => {
    mouseX.set(mousePosition.x - (isHoveringClickable ? 100 / 2 : 20 / 2)); // Center the cursor
    mouseY.set(mousePosition.y - (isHoveringClickable ? 100 / 2 : 20 / 2));
  }, [mousePosition, mouseX, mouseY, isHoveringClickable]);

  return (
    <motion.div
    className='follow-cursor'
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: isHoveringClickable ? 100 : 20,
        height: isHoveringClickable ? 100 : 20,
        borderRadius: '50%',
        backgroundColor: isHoveringClickable ? 'rgba(101, 153, 115, 0.1)' : 'rgba(101, 153, 115, 1)', 
        filter: isHoveringClickable ? 'blur(20px)' : 'blur(5px)',
        zIndex: 1000,
        x,
        y,
        pointerEvents: 'none',
        transition: 'height 0.3s ease-out, width 0.3s ease-out, background-color 0.3s ease-out, filter 0.3s ease-out'
      }}
    />
  );
};

export default FollowCursor;