import React from 'react';
import "../css/Footer.css"

const Footer = () => {
  return (
    <footer className='footer'>
      <img className='background-image op' src="/footer.jpg" alt="" />
      <p>&copy; {new Date().getFullYear()} AbdelRahman Amin - All Rights Reserved</p>
    </footer>
  );
};

export default Footer;