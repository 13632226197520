import React from 'react';
import '../css/Buttons.css';
import { Link, useNavigate } from 'react-router-dom';

export default function Buttons(props) {
    const navigate = useNavigate(); // Use the useNavigate hook

    const handleNavigate = (path) => {
        window.scrollTo(0, 0); // Scroll to top
        navigate(path);
    };

    
  const phoneNumber = "+201150888110"; // Your phone number
  const encodedMessage = encodeURIComponent("Hello! I need help."); // Optional default message

    return (
        <div style={props.lineup ? { display: 'inline' } : null} className={`buttons ${props.lineup ? 'lineup-buttonss' : null}`}>
            {
                props.main ?
                <button onClick={() => handleNavigate('/contact')} className="main-button">
                    Contact Me
                </button> : null
            }
            {
                props.lineup ?
                <button onClick={props.onClick} style={props.selected ? { backgroundColor: "white", color: 'black' } : null} className="lineup-button">
                    {props.text}
                </button> : null
            }
            {
                props.secondary ?
                <button onClick={() => handleNavigate('/projects')} className="secondary-button">
                    View Portfolio
                </button> : null
            }
            {
                props.mail ?
                <button onClick={() => window.location.href = 'mailto:abdelrahmanaminxx@gmail.com?subject=Hello&body=I would like to...'} className="secondary-button">
                    Email Me
                </button> : null
            }
            {
                props.link ?
                <div onClick={() => handleNavigate('/projects')}  className="button-link-container">
                    <Link className="link-button">
                        View Projects
                    </Link>
                    <img src="/arrow.svg" alt="" />
                </div> : null
            }
            {
                props.whatsapp ? 
                <div className='icons whatsapp' style={{cursor: 'pointer'}} onClick={() => window.open(`https://wa.me/${phoneNumber}?text=${encodedMessage}`, '_blank')}>
                    <span className='icon-container' style={{
                    // textDecoration: 'underline',
                    // backgroundColor: 'white',
                    border: '1px white solid',
                    borderRadius: '25px',
                    padding: '10px 20px',
                    textAlign: 'center',
                
                    }}>
                    <img 
                        src="/logos/whatsapp.svg" 
                        alt="Phone" 
                        style={{ width: '30px', height: 'auto', display: 'inline' }} 
                    />
                    <a 
                        href={`https://wa.me/${phoneNumber}?text=${encodedMessage}`} 
                        target="_blank" 
                        rel="noopener noreferrer"
                        style={{ marginLeft: '8px', textDecoration: 'none', color: 'inherit' }}
                    >
                        Contact Me On Whatsapp
                    </a>
                    </span>
                </div> 
            : null
            }
        </div>
    );
}